

export const environment = {
    production: true,
    banorte: false,
    ente: "H. Ayuntamiento de Eldorado",
    administracion: "2024 - 2027",
    url_escudo: "../../../assets/dorado/escudo_eldorado.png",
    url_administracion: "../../../assets/dorado/escudo_eldorado.png",
    url_footer: "../../../assets/dorado/escudo_eldorado.png",
    direcciones: [
        "Domicilio conocido S/N",
        "C.P. 80381",
        "Eldorado, Sinaloa, México"
    ],
    telefonos: [
        "667 208 0101"
    ],
    emails: [
        "ingresos@eldorado.gob.mx"
    ],
    url_aviso: "hhttps://pagoseldorado.com/",
    url_webapi: "http://pagoseldorado.azurewebsites.net/api/",
    url_sitio: "https://pagoseldorado.com/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/',
    cve_municipio: "019",
    firebase: {
        apiKey: "AIzaSyDXU-zZlQB6Z5xbv4_F1DgWjyJwX7zNDdA",
        authDomain: "pagoseldoradosin.firebaseapp.com",
        projectId: "pagoseldoradosin",
        storageBucket: "pagoseldoradosin.appspot.com",
        messagingSenderId: "79363716414",
        appId: "1:79363716414:web:b752288855966f05b2686f",
        measurementId: "G-4H2MMMDD1F"
    },
    municipio: "Eldorado",
    leyenda_fundamento: "Ley de ingresos del municipio de Eldorado para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    id_express: "",
    llave: "",
    img_ayuda_1: '',
    img_ayuda_2: '',
    img_ayuda_3: ''
};
